import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router';
import publicSystemsRouter from './publicSystems';
import pathologicalSystemRouter from './pathologicalSystem';
import preProcessingRouter from './preProcessing';
import inspectionWorkstationRouter from './inspectionWorkstation';
import storage from '@/utils/storage';
// import { useCameraStore } from '@/stores/camera';

export const constantRoutes: Array<RouteRecordRaw> = [
  //#region 登录页
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    component: () => import('@/views/login/Index.vue'),
    name: 'Login',
    meta: {
      title: 'login',
      affix: true
    }
  },
  {
    path: '/print',
    component: () => import('@/views/login/print.vue'),
    name: 'Print',
    meta: {
      title: '自助打印',
      affix: true
    }
  },
  {
    path: '/qualitySta',
    component: () => import('@/views/login/qualitySta.vue'),
    name: 'qualitySta',
    meta: {
      title: '质量统计',
      affix: true
    }
  },
  {
    path: '/dashboard',
    component: () => import('@/views/dashboard/Index.vue'),
    name: 'Dashboard',
    meta: {
      title: '首页',
      affix: true
    }
  },
  ...publicSystemsRouter,
  ...pathologicalSystemRouter,
  ...preProcessingRouter,
  ...inspectionWorkstationRouter,
];


const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(import.meta.env.VITE_APP_NAME as any),
  routes: constantRoutes
});

router.beforeEach((to, from, next) => {
  // const store = useCameraStore();

  // console.log('to:' + to.fullPath);
  // console.log('from:' + from.fullPath);
  // store.setCurrentRoute(to);
  document.title = `${to.meta.title ? to.meta.title : '实验室系统'}`;
  const token: any = storage.getToken();
  if (to.path === '/login'  || to.path === '/print' ||  to.path === '/qualitySta') {
    return next();
  } else {
    if (token) {
      return next();
    } else {
      return next('/login');
    }
  }
  // if (!token && from.fullPath !== '/') return false;

  // 返回 false 以取消导航
  // if (to.fullPath == '/authorityManagement/userManagement') {
  //   // 禁止访问
  //   return false;
  // }

  // if (to.fullPath == '/preProcessing/abnormalHandoff') {
  //   // 路由重定向
  //   router.push('/preProcessing/barcodeManagement');
  // }
  next();
});

export default router;
